import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import ReactGA from "react-ga";

ReactGA.initialize("UA-57971817-22");
ReactGA.pageview("/start");

const SlideList = [
  {
    textPosition: "text-left",
    category: "Willkommen in meiner Welt",
    description: "",
    buttonText: "",
    buttonLink: ""
  }
];

const PortfolioLanding = () => {
  let title = "Über mich",
    description =
      "Obwohl Du noch keinen Amateurporno von mir gesehen hast, komme ich Dir bekannt vor? Das ist gut möglich, denn die Presse hat schon ausführlich über mich berichtet. Ich bin als die Pornoerzieherin bekannt geworden, denn ich habe in einer evangelischen Einrichting als Erzieherin gearbeitet. Dort wurde mir jedoch gekündigt, weil ich in meiner Freizeit gerne Pornos drehe.";
  return (
    <div className="active-dark">
      <Helmet pageTitle="Willkommen in meiner Welt" />

      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        Hallo, ich bin Julia Pink <br />
                        <TextLoop>
                          <span> MILF.</span>
                          <span> Pornodarstellerin.</span>
                          <span> Pornoerzieherin.</span>
                        </TextLoop>{" "}
                      </h1>
                      <h2>aus Bayern.</h2>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div id="uebermich" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-8.jpg"
                      alt="&Uuml;ber Julia Pink"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="amateurvideos" className="fix">
        <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center">
                  <h2>Julia Pink Amateurpornos</h2>
                  <p>
                    Hier findest Du eine Auswahl meiner Amateurpornos, die ich
                    bis jetzt gedreht habe.
                    <br />
                    In meinem Mitgliederbereich hast Du natürlich Zugriff auf
                    alle Pornos, die ich bisher online gestellt habe.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60 mt_sm--40">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296482.jpg`}
                        alt="Krasse Schwanz und Eierspiele || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Schwanz- &amp; Eierspiele</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296438.jpg`}
                        alt="Bewerber komplett ausgesaugt || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Bewerber ausgesaugt</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296381.jpg`}
                        alt="Hart in meinen Arsch gefickt || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Harter Arschfick</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296253.jpg`}
                        alt="Verfickte Hotelparty || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Verfickte Hotelparty</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296226.jpg`}
                        alt="Geiler Bewerberfick || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Geiler Bewerberfick</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296217.jpg`}
                        alt="Lesbische Bananenspiele || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Lesbische Bananenspiele</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296216.jpg`}
                        alt="Du hast STANGENFIEBER ? Dann Doc Pink rufen || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Doc Pink hilft</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/296030.jpg`}
                        alt="Meinen Neffen abgemolken || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Neffen abgemolken</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295935.jpg`}
                        alt="Ich saug dich aus bis du schreist || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Ich saug dich aus</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295885.jpg`}
                        alt="Geweckt und gefickt || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Geweckt und gefickt</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295884.jpg`}
                        alt="Versaute Untersuchung || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Versaute Untersuchung</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295883.jpg`}
                        alt="Extreme Sklavenspiele XXL Version || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Extreme Sklavenspiele</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295783.jpg`}
                        alt="Mathesch&uuml;ler belohnt || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Mathesch&uuml;ler belohnt</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295782.jpg`}
                        alt="POV voll ins Maul gewichst || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">POV voll ins Maul gewichst</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <a href="#registrierung">
                      <img
                        className="w-100"
                        src={`/assets/images/videos/295781.jpg`}
                        alt="Doktor Black || Julia Pink Amateurpornos"
                      />
                    </a>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="#registrierung">Doktor Black</a>
                    </h4>
                    <div className="blog-btn">
                      <a className="rn-btn text-white" href="#registrierung">
                        Video abspielen
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="registrierung" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactThree
            contactImages="/assets/images/about/about-9.jpg"
            contactTitle="MILF Julia Pink"
          />
        </div>
      </div>

      <FooterTwo />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default PortfolioLanding;
