import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Vorlieben",
      tab2 = "Awards";
    const { tabStyle } = this.props;
    return (
      <div>
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>Analsex, Deepthroat, DirtyTalk</li>
                        <li>Swingerclubs, Rimming</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          Venus Award{" "}
                          <span>
                            - <b>Best MILF</b>
                          </span>{" "}
                          2015
                        </li>
                        <li>
                          Venus Award{" "}
                          <span>
                            - <b>Best Shootingstar</b>
                          </span>{" "}
                          2014
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TabsTwo;
