import React, { Component } from "react";

class ContactThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vorName: "",
      nachName: "",
      Email: ""
    };
  }
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{this.props.contactTitle}</h2>
                <p className="description">
                  Wenn Du alle meine privaten Pornos in voller L&auml;nge sehen
                  m&ouml;chtest, dann solltest Du Dich jetzt f&uuml;r meinen
                  Mitgliederbereich registrieren.
                  <br />
                  Das Testabo kostet nur <b>4,95 Euro</b> (7 Tage) und damit
                  bekommst Du Zugriff auf alle Amateurpornos, die ich bisher in
                  meinen Mitgliederbereich online gestellt habe. Oder entscheide
                  Dich gleich f&uuml;r ein{" "}
                  <b>Monatsabo f&uuml;r nur 29,80 Euro</b> oder ein 3-Monatsabo
                  f&uuml;r nur 59,80 Euro.
                </p>
              </div>
              <div className="form-wrapper">
                <form
                  method="post"
                  action="https://www.online-pay.net/payment/step1/"
                  target="_blank"
                >
                  <input type="hidden" name="pid" value="135" />
                  <input type="hidden" name="wid" value="200678" />
                  <input type="hidden" name="sid" value="379333" />
                  <input type="hidden" name="aktion" value="" />

                  <label htmlFor="vorname">
                    <input
                      type="text"
                      name="vorname"
                      id="vorname"
                      value={this.state.vorName}
                      onChange={e => {
                        this.setState({ vorName: e.target.value });
                      }}
                      placeholder="Vorname *"
                    />
                  </label>
                  <label htmlFor="nachname">
                    <input
                      type="text"
                      name="nachname"
                      id="nachname"
                      value={this.state.nachName}
                      onChange={e => {
                        this.setState({ nachName: e.target.value });
                      }}
                      placeholder="Nachname *"
                    />
                  </label>
                  <label htmlFor="email">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={this.state.Email}
                      onChange={e => {
                        this.setState({ Email: e.target.value });
                      }}
                      placeholder="Mailadresse *"
                    />
                  </label>
                  <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    Jetzt registrieren
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  src={`${this.props.contactImages}`}
                  alt="MILF Julia Pink"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactThree;
