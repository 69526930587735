import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} || JuliaPink.fun</title>
          <meta
            name="description"
            content="Julia Pink - MILF, Pornodarstellerin, Pornoerzieherin. Ich bin in der Presse als Pornoerzieherin bekannt geworden. Ich bin aus einer evangelischen Einrichtung entlassen worden, weil ich in meiner Freizeit schon immer gerne Pornofilme gedreht habe."
          />
        </Helmet>
      </React.Fragment>
    );
  }
}
export default PageHelmet;
